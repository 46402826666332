import { ReportVizChartColors } from './constants';
import * as Highcharts from 'highcharts';
var us_map = require('@highcharts/map-collection/countries/us/us-all.topo.json');

function getChartEvents(position: 'right' | 'left' | 'center' = 'right') {
  const logoUrl = 'assets/img/logo-white.svg';
  return {
    load: function () {
      const chart = this as any;
      const logoX =
        position === 'right'
          ? chart.chartWidth - 131
          : position === 'center'
            ? chart.chartWidth / 2 - 60
            : 10;

      chart.customLogo = chart.renderer
        .image(logoUrl, logoX, chart.chartHeight - 34, 121, 24)
        .add();

      // Add the class to the <img> element
      if (chart.customLogo && chart.customLogo.element) {
        chart.customLogo.element.setAttribute('class', 'ldt-logo');
      }
    },
    redraw: function () {
      const chart = this as any;
      const logo = chart.customLogo;
      let logoWidth = 121;
      let logoHeight = 24;
      if (chart.chartWidth < 480) {
        logoWidth = 90; // Smaller width for smaller containers
        logoHeight = logoWidth / 5.04; // Maintain aspect ratio
      }
      const logoX =
        position === 'right'
          ? chart.chartWidth - (logoWidth + 10)
          : position === 'center'
            ? chart.chartWidth / 2 - logoWidth / 2
            : 10;

      if (logo) {
        logo.attr({
          x: logoX,
          y: chart.chartHeight - (logoHeight + 10),
          width: logoWidth,
          height: logoHeight,
        });
      }
    },
  };
}

function tooltipValueLabel(thisParam: any, useXLabel = false): string {
  const series = thisParam.series;
  const pointValue = thisParam.y || 0;
  const isPercentage = ['Pct Change', '% of employees'].includes(
    (series?.yAxis as any).axisTitle?.textStr
  );

  const trimmedPoint =
    pointValue < 10
      ? pointValue.toFixed(2)
      : pointValue.toLocaleString(undefined, { maximumFractionDigits: 0 });

  const formattedValue = isPercentage ? trimmedPoint + '%' : trimmedPoint;

  return `${useXLabel ? thisParam.x : series.name}: <b>${formattedValue}</b>`;
}

function tooltipPointFormatter(point: Highcharts.Point): string {
  return tooltipValueLabel(point);
}

export const formatLargeNumber = (value: number | string, decs: number = 1): string => {
  if (typeof value === 'string') {
    return value;
  }
  if (value >= 1000000) {
    if (value % 1000000 === 0) decs = 0;
    return (value / 1000000).toFixed(decs) + 'M';
  } else if (value >= 1000) {
    if (value % 1000 === 0) decs = 0;
    return (value / 1000).toFixed(decs) + 'K';
  }
  return value.toString();
};

export const reportBaseOptions: Highcharts.Options = {
  colors: ReportVizChartColors,
  caption: {
    useHTML: true,
    style: {
      fontSize: '.6em',
    },
  },
  credits: {
    enabled: false,
  },
  title: {
    text: 'Headcount',
    align: 'center',
    style: {
      fontWeight: '600',
    },
  },
  chart: {
    spacingTop: 15, // a bit of top padding
    events: getChartEvents(),
  },
  plotOptions: {
    bar: {
      cursor: undefined,
      borderWidth: 0,
      borderColor: 'transparent',
      dataLabels: {
        enabled: true,
        style: {
          backgroundColor: 'white',
          fontWeight: 'bold',
          fontSize: '13px',
          textOutline: '3px #ffffff',
        },
        color: '#000000',
      },
    },
    series: {
      label: {
        connectorAllowed: false,
      },
      states: {
        hover: {
          enabled: false, // Disable default hover // NOT IN PREV
        },
      },
      marker: {
        enabled: false, // Disable data points
      },
      dataLabels: {
        enabled: false,
      },
      linecap: 'round', // Make the lines rounded instead of angular
    },
    column: {
      stacking: 'normal',
      borderWidth: 0,
      borderColor: 'transparent',
    },
    sankey: {
      dataLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          fontSize: '13px',
          textOutline: '3px #ffffff',
        },
        color: '#000000',
      },
      states: {
        hover: {
          enabled: true,
        },
      },
      nodeWidth: 200,
      nodePadding: 8,
    },
  },
  tooltip: {
    useHTML: true,
    // formatter: groupIconTooltipFormatter,
    pointFormatter: function (this: Highcharts.Point): string {
      return tooltipPointFormatter(this);
    },
  },
};

export const reportChartLineOptions = Highcharts.merge(reportBaseOptions, {
  chart: {
    type: 'line',
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      day: '%e %b %Y', // format for the dates on the xAxis
    },
  },
  yAxis: [
    {
      allowDecimals: false,
      title: {
        text: 'Average Tenure (months)',
      },
      stackLabels: {
        enabled: false,
      },
    },
  ],
  legend: {
    useHTML: true,
    layout: 'horizontal',
    align: 'left',
    verticalAlign: 'top',
    // labelFormatter: function () { // TODO WAS IN PREV
    //   return labelFormatter(this as Highcharts.Series);
    // },
  },
});

export const reportChartBarOptions = Highcharts.merge(reportBaseOptions, {
  chart: {
    type: 'bar',
    events: getChartEvents('left'),
  },
  tooltip: {
    enabled: true,
    formatter: function (this: Highcharts.Point) {
      return tooltipValueLabel(this, true);
    },
  },
});

export const reportSankeyOptions = Highcharts.merge(reportBaseOptions, {
  chart: {
    events: getChartEvents('center'),
  },
  tooltip: {
    useHTML: false,
    pointFormatter: null,
  },
  series: {
    // label: {
    //   connectorAllowed: true,
    // },
    // marker: {
    //   enabled: true,
    // },
    // dataLabels: {
    //   enabled: true,
    // },
    // linecap: 'round',
    // tooltip: {},
  },
  legend: { enabled: false },
  yAxis: { labels: { enabled: false } },
});

export const reportTreemapOptions = Highcharts.merge(reportBaseOptions, {
  chart: {
    events: getChartEvents('center'),
    type: 'treemap',
    marginBottom: 40,
  },
  tooltip: {
    // useHTML: false,
    // formatter: null,
    pointFormatter: null,
  },
});

export const reportUSMapOptions = Highcharts.merge(reportBaseOptions, {
  chart: {
    map: us_map,
    borderWidth: 0,
    marginTop: 55,
  },
  legend: {
    layout: 'horizontal',
    borderWidth: 0,
    floating: true,
    verticalAlign: 'top',
    itemMarginTop: 20,
  },
  mapNavigation: {
    enabled: true,
    enableMouseWheelZoom: false,
  },
  tooltip: {
    enabled: true,
    formatter: null,
    pointFormatter: null,
  },
  colorAxis: {
    min: 0,
    maxColor: ReportVizChartColors[0],
  },
  exporting: {
    sourceWidth: 1200,
  },
});

export const columnChartXAaxis: Highcharts.XAxisOptions = {
  type: 'category',
  categories: undefined,
  labels: {
    useHTML: true,
    formatter: function () {
      const hasGroup = this.value.toString().endsWith('(groupicon)');
      const val = this.value.toString().replace('(groupicon)', '');
      return (
        '' +
        val +
        (hasGroup
          ? ' <img src="../../assets/img/office-building.png" alt="office building conglomerate" class="tw-w-4 tw-align-middle tw-mb-1">'
          : '')
      );
    },
  },
};

const arrDepOriginalOptions: Highcharts.Options = {
  caption: {
    useHTML: true,
    style: {
      fontSize: '.6em',
    },
    text: 'Source: Live Data Technologies',
  },
  chart: {
    spacingTop: 15, // a bit of top padding
    events: getChartEvents(),
  },
  credits: {
    enabled: false,
  },
  title: {
    text: 'Headcount',
    align: 'center',
    style: {
      fontWeight: '600',
    },
  },
  yAxis: [
    {
      allowDecimals: false,
      title: {
        text: 'Number of Employees',
      },
      stackLabels: {
        enabled: false,
      },
      labels: {
        formatter: function () {
          return formatLargeNumber(this.value);
        },
      },
    },
  ],
  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
      cursor: 'pointer',
      states: {
        hover: {
          // enabled: false, // Disable default hover
        },
      },
      marker: {
        enabled: false, // Disable data points
      },
      dataLabels: {
        enabled: false,
      },
      linecap: 'round', // Make the lines rounded instead of angular
    },
    column: {
      stacking: 'normal',
      borderWidth: 0,
      borderColor: 'transparent',
    },
  },
  legend: {
    layout: 'horizontal',
    align: 'left',
    verticalAlign: 'top',
    useHTML: true,
  },
  // change content based on switch value, show building icon if group
  tooltip: {
    useHTML: true,
  },
};

export const arrDepChartNoSeries: Highcharts.Options = Highcharts.merge(
  reportBaseOptions,
  reportChartLineOptions,
  arrDepOriginalOptions
);
