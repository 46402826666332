import { Component } from '@angular/core';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { DestinationType, DestinationsService } from 'ldt-data-deliveries-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { DarkModeService } from 'src/app/shared/dark-mode/dark-mode.service';
import { AuthService } from 'src/app/auth/service/auth.service';

interface ParamsWithDestinationsData extends ICellRendererParams {
  data: DestinationType;
}

@Component({
  selector: 'app-destination-types',
  templateUrl: './destination-types.component.html',
  styleUrls: ['./destination-types.component.scss'],
})
export class DestinationTypesComponent {
  refreshing: boolean = false;

  constructor(
    private destinationsService: DestinationsService,
    private notify: NotificationService,
    public darkModeService: DarkModeService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.getAllDestinationTypes();
  }

  /// AG-GRID to display all destination types --------------
  public rowDataTypes!: DestinationType[];
  public colDefsTypes: ColDef[] = [
    { field: 'id', maxWidth: 60 },
    { field: 'display_name', floatingFilter: true },
    { field: 'description' },
    {
      field: 'required_fields',
      headerName: 'Required Form Fields',
      cellRenderer: this.requiredFieldsCellRenderer,
      autoHeight: true,
    },
    { field: 'supported_frequencies', valueFormatter: (params) => params.value.join(', ') },
  ];

  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    // floatingFilter: true,
    resizable: true,
    flex: 1,
  };

  getAllDestinationTypes(): void {
    this.refreshing = true;
    this.destinationsService.listDestinationTypes(this.auth.getSelectedOrgIdValue).subscribe({
      next: (data) => {
        this.rowDataTypes = data;
        this.refreshing = false;
      },
      error: (error) => {
        this.notify.error('Error fetching destination types');
        this.refreshing = false;
      },
    });
  }

  public requiredFieldsCellRenderer(params: ParamsWithDestinationsData): string {
    const htmlString = (params.data as any).fields
      .map((field: DestinationType) => {
        const items = Object.entries(field)
          .map(([key, value]) => {
            const displayValue = value !== null && value !== undefined ? value.toString() : '';
            // Convert value to a string if it's not already (e.g., for boolean values)
            return `<li class="tw-leading-4">${key}: <span class="tw-inline-block code-font-direct">${displayValue}</span></li>`;
          })
          .join('');
        return `<div class="tw-font-semibold">Input Field: </div>
        <ul class="config-info-list tw-ml-2 tw-pl-4 tw-mt-0 tw-mb-2">${items}</ul>`;
      })
      .join('');
    return htmlString;
  }
}
