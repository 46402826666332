<a routerLink="/admin/identity" class="tw-text-link-color-dynamic hover:tw-underline">
  Back to Identity Admin
</a>

<div *ngIf="org">
  <h1 class="tw-text-dark-light-dynamic tw-font-semibold tw-my-3">{{org.name}} ({{org.id}})</h1>
  <div class="content-description tw-mt-3">
    <strong>Created:</strong> {{org.createdAt | date:'medium'}}
  </div>

  <mat-card class="tw-my-4">
    <h2 class="section-header">People Usage</h2>

    <div class="tw-flex tw-flex-col tw-gap-2">
      <div>Total Usage: {{peopleUsageTotal | number}}</div>
      <div>API Usage: {{peopleUsageApi | number}}</div>
      <div>Delivery Usage: {{peopleUsageDelivery | number}}</div>
    </div>
  </mat-card>

  <app-org-settings-editor [initialSettings]="org.settings" #settingsForm>
  </app-org-settings-editor>

  <div class="tw-flex tw-flex-row tw-gap-4">
    <button
      mat-raised-button
      [color]="org.status === 'active' ? 'warn' : 'primary'"
      type="button"
      (click)="toggleOrgActive()">
      {{org.status === 'active' ? 'Deactivate' : 'Activate'}} Organization
    </button>
    <button mat-raised-button type="button" (click)="updateOrgSettings()">Save Settings</button>
    <button mat-raised-button type="button" (click)="observeOrg()">Observe</button>
  </div>
</div>

<h2 class="section-header tw-mt-8">Users</h2>
<div class="tw-flex tw-gap-4 tw-mb-4">
  <button mat-raised-button (click)="showInviteDialog()">Invite users</button>
  <button mat-raised-button color="warn" [disabled]="!rowsSelected" (click)="removeSelectedUsers()">
    Remove Selected Users
  </button>
</div>
<div>
  <ag-grid-angular
    [ngClass]="{ 'ag-theme-balham': !(darkModeService.darkMode$ | async), 'ag-theme-balham-dark': darkModeService.darkMode$ | async }"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    accentedSort="true"
    [components]="components"
    [rowSelection]="rowSelection"
    [defaultColDef]="defaultColDef"
    enableCellTextSelection="true"
    ensureDomOrder="true"
    (gridReady)="onGridReady($event)"
    (selectionChanged)="onSelectionChanged()"
    [domLayout]="'autoHeight'">
  </ag-grid-angular>
</div>

<div class="tw-mt-4">
  <mat-accordion id="advanced-add-users-accordion">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Manual Add </mat-panel-title>
        <mat-panel-description> For advanced users only </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="tw-flex tw-gap-4 tw-items-center">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Email addresses</mat-label>
          <input matInput placeholder="Emails to add" [(ngModel)]="emails" />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Role</mat-label>
          <mat-select [(ngModel)]="role">
            <mat-option name="role" *ngFor="let role of roles" [value]="role">
              {{role}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-raised-button [disabled]="!emails && !role" (click)="addUsers()">
          Add users
        </button>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
