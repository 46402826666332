import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CompletePasswordResetRequest, UsersService } from 'ldt-identity-service-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  submitButtonValue = 'Reset';
  email: string = '';
  password: string = '';
  showPassword: boolean = false;
  resetId = '';
  emailSent: boolean = false;
  enterPassword: boolean = false;
  validationError: boolean = false;
  isValidating: boolean = false;
  isOnboarding: boolean = false;

  form = this.fb.group({
    email: new FormControl<string>('', {
      validators: [Validators.required, Validators.email],
    }),
    passwordFormControl: new FormControl<string>('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(64),
    ]),
  });
  pValidation = [
    {
      func: this.hasLength,
      text: 'Between 8 and 64 characters',
      valid: true,
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private usersService: UsersService,
    private notify: NotificationService
  ) {
    this.route.queryParams.subscribe((params) => {
      if ('email' in params && 's' in params) {
        this.form.controls['email'].setValue(params.email);
        this.resetId = params.s;
        this.enterPassword = true;
      }
      if ('source' in params && params.source === 'onboarding') {
        this.isOnboarding = true;
      }
    });
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  hasLength(pass: string) {
    return pass.length >= 8 && pass.length <= 64;
  }

  checkingPassword(event: any) {
    this.password = event.target.value;
    this.pValidation.forEach((v) => {
      v.valid = v.func(this.password);
    });
  }

  initiateReset() {
    const email = this.form.value.email;

    if (!email) return;
    this.usersService.initiatePasswordReset({ email: email }).subscribe({
      next: () => {
        this.notify.success(
          "If an account with this email exists, you'll receive a password reset email shortly.",
          30000
        );
        this.emailSent = true;
      },
      error: () => {
        this.notify.error('Error resetting your password. Please try again.');
      },
    });
  }

  completeReset() {
    const email = this.form.value.email;
    const passwordFormControl = this.form.value.passwordFormControl ?? '';

    if (!email || !this.resetId) {
      this.notify.error('Missing information. Please ensure all fields are filled.');
      return;
    }

    if (!this.form.valid || !this.hasLength(passwordFormControl)) {
      this.validationError = true;
      return;
    }

    let rBody: CompletePasswordResetRequest = {
      resetId: this.resetId,
      email: email,
      password: passwordFormControl,
    };

    this.usersService.completePasswordReset(rBody).subscribe({
      next: () => {
        this.notify.success('Password reset successful. Please login.');
        this.router.navigate(['/login']);
      },
      error: (error) => {
        if (error.status === 410) {
          this.notify.error(
            'The password reset has expired. Reset links must be used within 48 hours.'
          );
          return;
        }

        this.notify.error('Oops. There was an error during your request. Please try again later.');
      },
    });
  }

  goToLogin() {
    this.router.navigateByUrl('/login');
  }
}
