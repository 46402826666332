import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import {
  AdminService,
  ListAllReportDeliveriesRequest,
  ReportDelivery,
} from 'ldt-data-deliveries-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { DarkModeService } from 'src/app/shared/dark-mode/dark-mode.service';
import * as Highcharts from 'highcharts';
import { lightTheme } from 'src/app/shared/highcharts-themes';
import { darkTheme } from 'src/app/shared/highcharts-themes';
import { Org, OrganizationsService } from 'ldt-identity-service-api';
import * as dayjs from 'dayjs';
import { formatLargeNumber } from 'src/app/shared/report-visualizations/highcharts-options';

@Component({
  selector: 'app-report-deliveries',
  templateUrl: './report-deliveries.component.html',
  styleUrls: ['./report-deliveries.component.scss'],
})
export class ReportDeliveriesComponent implements OnInit, AfterViewInit {
  refreshing: boolean = false;
  reportDeliveries: ReportDelivery[] = [];
  Highcharts: typeof Highcharts = Highcharts;
  orgs: Org[] = [];
  heatmapOptions: Highcharts.Options = {
    chart: {
      type: 'heatmap',
      marginTop: 40,
      marginBottom: 80,
      plotBorderWidth: 1,
    },
    title: {
      text: 'Report Deliveries Heatmap',
    },
    xAxis: {
      title: { text: undefined },
      type: 'datetime',
      labels: {
        format: '{value:%Y-%m-%d}',
        rotation: -45,
        align: 'right',
        style: {
          fontSize: '10px',
        },
      },
    },
    yAxis: {
      title: { text: undefined },
      categories: [],
      labels: {
        style: {
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          width: 150,
        },
      },
    },
    colorAxis: {
      min: 0,
      minColor: this.darkModeService.currentDarkModeValue ? '#000000' : '#FFFFFF',
      maxColor: this.darkModeService.currentDarkModeValue ? '#8e91ff' : '#8e91ff',
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        const categories = this.series.yAxis.categories;
        if (!categories) return '';
        return `<b>${categories[this.point.y!]}</b><br>${this.point.value!.toLocaleString()} records<br>${Highcharts.dateFormat('%Y-%m-%d', this.point.x)}`;
      },
    },
    series: [
      {
        type: 'heatmap',
        name: 'Records Delivered',
        borderWidth: 1,
        data: [],
        dataLabels: {
          enabled: true,
          color: this.darkModeService.currentDarkModeValue ? '#FFFFFF' : '#000000',
          formatter: function () {
            return this.point.value ? formatLargeNumber(this.point.value) : this.point.value;
          },
        },
        colsize: 24 * 36e5, // 24 hours in milliseconds
        pointPadding: 0,
        tooltip: {
          pointFormat: '{point.value} records',
        },
      },
    ],
  };

  public colDefs: ColDef[] = [
    { field: 'id', maxWidth: 60 },
    { field: 'org_id', maxWidth: 110, filter: 'agSetColumnFilter' },
    {
      field: 'date_for',
      maxWidth: 230,
      valueFormatter: (params) => dayjs(params.value).format('YYYY-MM-DD'),
    },
    { field: 'status', maxWidth: 230 },
    { field: 'record_count', maxWidth: 110 },
    {
      field: 'scheduled_report_metadata.report_definition.source.name',
      headerName: 'Source',
      maxWidth: 230,
    },
    { field: 'scheduled_report_metadata.destination.name', headerName: 'Dest Name', maxWidth: 230 },
    {
      field: 'scheduled_report_metadata.destination.destination_type.display_name',
      headerName: 'Dest Type',
      maxWidth: 230,
    },
    {
      field: 'scheduled_report_metadata.destination.status',
      headerName: 'Dest Status',
      maxWidth: 230,
    },

    { field: 's3_url', headerName: 'Delivered To' },
    { field: 'created_at', maxWidth: 230 },
    { field: 'scheduled_report_metadata.user_id', headerName: 'Created By', maxWidth: 230 },
  ];

  public defaultColDef: ColDef = {
    sortable: true,
    floatingFilter: true,
    resizable: true,
    flex: 1,
    filter: 'agTextColumnFilter',
  };

  constructor(
    private reportDeliveriesService: AdminService,
    private notify: NotificationService,
    public darkModeService: DarkModeService,
    private orgsService: OrganizationsService
  ) {}

  ngOnInit(): void {
    this.orgsService.getOrgs().subscribe((orgs) => {
      this.orgs = orgs;
      this.getReportDeliveries();
    });
    Highcharts.setOptions(this.darkModeService.currentDarkModeValue ? darkTheme : lightTheme);
  }

  ngAfterViewInit(): void {
    this.heatmapChart = Highcharts.chart('heatmapContainer', this.heatmapOptions);
  }

  getReportDeliveries(): void {
    this.refreshing = true;
    let req: ListAllReportDeliveriesRequest = {
      size: 1000,
      date_for: {
        gte: dayjs().subtract(45, 'days').format('YYYY-MM-DD'),
      },
    };
    this.reportDeliveriesService.listAllReportDeliveries(req).subscribe({
      next: (data) => {
        this.reportDeliveries = data.items || [];

        this.refreshing = false;
        if (data.pagination_token) {
          this.notify.warning('Only first 1000 destinations are displayed, but there are more!');
        }
        this.updateHeatmapData();
      },
      error: (error) => {
        this.notify.error('Error fetching report deliveries');
        this.refreshing = false;
      },
    });
  }

  private getDayTimestamp(date: string): number {
    return dayjs(date).startOf('day').valueOf();
  }

  private updateHeatmapData(): void {
    // First we gather all the unique report names, which are org + report name
    const reportNames: { [key: number]: string } = {};
    this.reportDeliveries.forEach((d) => {
      const id = (d.scheduled_report_metadata as any).id;
      if (!reportNames[id]) {
        const name =
          this.orgs.find((o) => o.id === d.org_id)?.name +
            ' - ' +
            (d.scheduled_report_metadata as any).name || 'Unknown';
        reportNames[id] = name;
      }
    });

    // Then we gather all the unique dates and sort them
    const dates = [
      ...new Set(
        this.reportDeliveries.map((d) => this.getDayTimestamp(d.date_for || d.created_at))
      ),
    ].sort((a, b) => a - b);

    // Then we set the yAxis categories to the report names
    this.heatmapOptions.yAxis = {
      ...this.heatmapOptions.yAxis,
      categories: Object.values(reportNames).sort((a, b) => b.localeCompare(a)),
    };

    // Finally we create normalized heatmap data
    const heatmapData: [number, number, number, number][] = []; // [date, y, value, normalizedValue]

    // For each report+date combo, we iterate through all the received data and reduce it to a single value
    Object.entries(reportNames)
      .sort((a, b) => b[1].localeCompare(a[1]))
      .forEach(([idString, _], y) => {
        const id = parseInt(idString);

        // First we gather all the unique counts for this report+date combo
        // This lets us calculate the min/max for this category specifically, so we can
        // color the cells relative to this "row" instead of across the entire heatmap
        const categoryValues: number[] = [];
        dates.forEach((date) => {
          const deliveries = this.reportDeliveries.filter(
            (d) =>
              id === (d.scheduled_report_metadata as any).id &&
              this.getDayTimestamp(d.date_for || d.created_at) === date
          );
          if (deliveries.length > 0) {
            const totalRecords = deliveries.reduce((sum, d) => sum + (d.record_count ?? 0), 0);
            categoryValues.push(totalRecords);
          }
        });

        // Calculate min/max for this category
        const minValue = Math.min(...categoryValues);
        const maxValue = Math.max(...categoryValues);
        const valueRange = maxValue - minValue;

        // Now we can add all the data for this report, and include a normalized
        // color scale for each cell for this row
        dates.forEach((date) => {
          const deliveries = this.reportDeliveries.filter(
            (d) =>
              id === (d.scheduled_report_metadata as any).id &&
              this.getDayTimestamp(d.date_for || d.created_at) === date
          );
          if (deliveries.length > 0) {
            const totalRecords = deliveries.reduce((sum, d) => sum + (d.record_count ?? 0), 0);
            // Normalize value between 0 and 1
            const normalizedValue = valueRange === 0 ? 0.5 : (totalRecords - minValue) / valueRange;
            heatmapData.push([date, y, totalRecords, normalizedValue]);
          }
        });
      });

    // Update series data
    this.heatmapOptions.series = [
      {
        type: 'heatmap',
        name: 'Records Delivered',
        borderWidth: 1,
        data: heatmapData.map((point) => ({
          x: point[0],
          y: point[1],
          value: point[2],
          color: Highcharts.color(this.darkModeService.currentDarkModeValue ? '#8e91ff' : '#8e91ff')
            .setOpacity(point[3])
            .get(),
        })),
        dataLabels: {
          enabled: true,
          color: this.darkModeService.currentDarkModeValue ? '#FFFFFF' : '#000000',
          formatter: function () {
            return this.point.value ? formatLargeNumber(this.point.value, 0) : this.point.value;
          },
        },
        colsize: 24 * 36e5,
        pointPadding: 0,
        tooltip: {
          pointFormat: '{point.value} records',
        },
      },
    ];

    // Remove colorAxis as we're handling colors manually
    delete this.heatmapOptions.colorAxis;

    // Update chart
    if (this.heatmapChart) {
      this.heatmapChart.update(this.heatmapOptions);
    }
  }

  private heatmapChart: Highcharts.Chart | undefined;
}
